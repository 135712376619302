import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { randomString } from '@script/utilities';
import * as LogRocket from 'logrocket';
import { BehaviorSubject, filter, first, firstValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HasuraRole } from './hasura-role.enum';
import { IdTokenClaims } from './id-token-claims.interface';
import { Tool } from './tool.enum';
import { UserGroup } from './user-group.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // TODO split this service in authentication and authorization services

  /** ROLES */
  private dashboardRolesSortedByAuthority = [
    HasuraRole.DASHBOARD_VIEWER_FULL
  ];
  private catRolesSortedByAuthority = [
    HasuraRole.CATALOG_ADMIN,
    HasuraRole.CATALOG_EDITOR,
    HasuraRole.CATALOG_VIEWER_FULL,
    HasuraRole.CATALOG_VIEWER_PART
  ];
  private cfgRolesSortedByAuthority = [
    HasuraRole.CONFIGURATOR_ADMIN,
    HasuraRole.CONFIGURATOR_EDITOR,
    HasuraRole.CONFIGURATOR_ADMIN_ARUP
  ];
  private mapRolesSortedByAuthority = [
    HasuraRole.MAP_VIEWER_FULL,
    HasuraRole.MAP_VIEWER_PART
  ];
  private indexRolesSortedByAuthority = [
    HasuraRole.INDEX_ADMIN,
    HasuraRole.INDEX_ADMIN_ARUP
  ];
  private addressBookRolesSortedByAuthority = [
    HasuraRole.ADDRESS_BOOK_ADMIN,
    HasuraRole.ADDRESS_BOOK_VIEWER_FULL
  ];

  private aliasRolesSortedByAuthority = [
    HasuraRole.ALIAS_ADMIN,
    HasuraRole.ALIAS_EDITOR
  ];

  /** GROUPS */
  private dashboardGroupsSortedByAuthority = [
    UserGroup.KIT_DASHBOARD_VIEWER_FULL,
    UserGroup.KIT_DASHBOARD_VIEWER_FULL_O365
  ];
  private catGroupsSortedByAuthority = [
    UserGroup.KIT_CATALOG_ADMIN,
    UserGroup.KIT_CATALOG_EDITOR,
    UserGroup.KIT_CATALOG_CONTRIBUTOR_O365,
    UserGroup.KIT_CATALOG_VIEWER_FULL,
    UserGroup.KIT_CATALOG_VIEWER_FULL_O365,
    UserGroup.KIT_CATALOG_VIEWER_PART,
    UserGroup.KIT_CATALOG_VIEWER_PART_O365
  ];
  private cfgGroupsSortedByAuthority = [
    UserGroup.KIT_CONFIGURATOR_ADMIN,
    UserGroup.KIT_CONFIGURATOR_EDITOR,
    UserGroup.KIT_CONFIGURATOR_ADMIN_ARUP
  ];
  private mapGroupsSortedByAuthority = [
    UserGroup.KIT_SMART_MAP_VIEWER_FULL,
    UserGroup.KIT_MAP_VIEWER_FULL_O365,
    UserGroup.KIT_SMART_MAP_VIEWER_EXT,
    UserGroup.KIT_MAP_VIEWER_PART_O365
  ];
  private indexGroupsSortedByAuthority = [
    UserGroup.KIT_INDEX_ADMIN,
    UserGroup.KIT_INDEX_ADMIN_ARUP
  ];
  private addressBookGroupsSortedByAuthority = [
    UserGroup.KIT_ADDRESS_BOOK_ADMIN,
    UserGroup.KIT_ADDRESS_BOOK_VIEWER_FULL
  ];

  lastAuthResult: AuthenticationResult;

  lastIdTokenClaims$: BehaviorSubject<IdTokenClaims | null> = new BehaviorSubject(null);
  roles$ = this.lastIdTokenClaims$.pipe(map((claims: IdTokenClaims) => claims?.roles as HasuraRole[]));
  authFlowOnError$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  identified = false;

  private inited = false;

  constructor(
    private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService

  ) {
  }

  async init() {
    if (this.inited) return;
    this.inited = true;
    try {
      await this.msalService.instance.initialize();
      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          first()
        )
        .subscribe(() => {
          let activeAccount = this.msalService.instance.getActiveAccount();
          if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
            activeAccount = this.msalService.instance.getAllAccounts()[0]
            this.msalService.instance.setActiveAccount(activeAccount);
          }
        });
    }
    catch (error) {
      this.authFlowOnError$.next(true);
      console.error('init auth', error);
    }
  }

  private async refreshAuthResult(forceRefresh = false) {
    try {
      if (!!this.lastAuthResult) {
        const now = Math.round(new Date().getTime() / 1000);

        const accessTokenExp = JSON.parse(atob(this.lastAuthResult.accessToken.split('.')[1])).exp;
        const idTokenExp = JSON.parse(atob(this.lastAuthResult.idToken.split('.')[1])).exp;

        // force refresh if one of the tokens expire within 60 seconds
        forceRefresh = forceRefresh
          || (now + 60) >= accessTokenExp
          || (now + 60) >= idTokenExp;
      }
      this.lastAuthResult = await firstValueFrom(this.msalService.acquireTokenSilent({
        scopes: environment.msalScope,
        forceRefresh,
      }));
      this.lastIdTokenClaims$.next(this.lastAuthResult.idTokenClaims as IdTokenClaims);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await this.msalService.instance.acquireTokenRedirect({
          scopes: environment.msalScope,
          state: JSON.stringify({
            pathNameAfterSignIn: window.location.pathname !== '/' ? window.location.pathname : null
          }),
          nonce: randomString(20)
        })
      } else {
        this.authFlowOnError$.next(true);
      }
    }
  }

  async logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.lastAuthResult = null;
    this.lastIdTokenClaims$.next(null);
    this.identified = false;
    await firstValueFrom(this.msalService.logoutRedirect());
  }

  async getIdToken() {
    await this.refreshAuthResult();
    return this.lastAuthResult.idToken;
  }
  async getIdTokenClaims() {
    await this.refreshAuthResult();
    return this.lastAuthResult.idTokenClaims as IdTokenClaims;
  }

  async getUID(): Promise<string | null> {
    return (await this.getIdTokenClaims()).oid;
  }
  async getEmail(): Promise<string | null> {
    return (await this.getIdTokenClaims()).email;
  }
  async getName(): Promise<string | null> {
    return (await this.getIdTokenClaims()).name;
  }

  private async identify() {
    if (environment.logRocketAppId) {
      if (!this.identified) {
        const uid = await this.getUID();
        if (uid) {
          const traits = {
            email: await this.getEmail(),
            name: await this.getName()
          };
          LogRocket.identify(uid, traits);
          this.identified = true;
          console.log('User identified!');
        } else {
          LogRocket.error('impossible to identify user');
        }
      }
    }
  }

  async getRoles(): Promise<HasuraRole[]> {
    return (await this.getIdTokenClaims())?.roles as HasuraRole[];
  }
  async hasRole(roles: HasuraRole): Promise<boolean> {
    return (await this.getIdTokenClaims())?.roles.includes(roles);
  }
  async hasOneOfRoles(roles: HasuraRole[]): Promise<boolean> {
    return (await this.getIdTokenClaims())?.roles.some((r: HasuraRole) => roles.includes(r));
  }
  async getFirstMatchingRole(roles: HasuraRole[]): Promise<HasuraRole> {
    const userRoles = await this.getRoles();
    const matchingRoles: HasuraRole[] = roles.filter(r => userRoles.includes(r));
    if (!matchingRoles.length) {
      throw new Error('No matching role for this user');
    }
    return matchingRoles[0];
  }

  async findGreaterRoleUserBetweenThese(roles: HasuraRole[], tool?: Tool) {
    const userRoles = await this.getRoles();
    const matchingRoles: HasuraRole[] = roles.filter(r => userRoles.includes(r));
    if (!matchingRoles.length) {
      throw new Error('No matching role for this user');
    }

    // return greater user role by tool
    let rsba: HasuraRole[];
    switch (tool) {
      case Tool.DASHBOARD:
        rsba = this.dashboardRolesSortedByAuthority;
        break;
      case Tool.CONFIGURATOR:
        rsba = this.cfgRolesSortedByAuthority;
        break;
      case Tool.SMART_MAP:
        rsba = this.mapRolesSortedByAuthority;
        break;
      case Tool.INDEX:
        rsba = this.indexRolesSortedByAuthority;
        break;
      case Tool.ADDRESS_BOOK:
        rsba = this.addressBookRolesSortedByAuthority;
        break;
      case Tool.ALIAS:
        rsba = this.aliasRolesSortedByAuthority;
        break;
      default:
        rsba = this.catRolesSortedByAuthority;
        break;
    }
    for (const r of rsba) {
      if (matchingRoles.includes(r)) {
        return r;
      }
    }
  }

  async findGreaterUserRole() {
    const userRoles = await this.getRoles();
    for (const hrsba of this.catRolesSortedByAuthority) {
      if (userRoles.includes(hrsba)) {
        return hrsba;
      }
    }
    return null;
  }
  async findGreaterUserRoleCfg() {
    const userRoles = await this.getRoles();
    for (const hrsba of this.cfgRolesSortedByAuthority) {
      if (userRoles.includes(hrsba)) {
        return hrsba;
      }
    }
    return null;
  }
  async findGreaterUserRoleIdx() {
    const userRoles = await this.getRoles();
    for (const hrsba of this.indexRolesSortedByAuthority) {
      if (userRoles.includes(hrsba)) {
        return hrsba;
      }
    }
    return null;
  }
  async findGreaterUserRoleMap() {
    const userRoles = await this.getRoles();
    for (const hrsba of this.mapRolesSortedByAuthority) {
      if (userRoles.includes(hrsba)) {
        return hrsba;
      }
    }
    return null;
  }
  async findGreaterUserRoleAddressBook() {
    const userRoles = await this.getRoles();
    for (const hrsba of this.addressBookRolesSortedByAuthority) {
      if (userRoles.includes(hrsba)) {
        return hrsba;
      }
    }
    return null;
  }

  async getGroups(): Promise<UserGroup[]> {
    return (await this.getIdTokenClaims())?.groups as UserGroup[];
  }

  async findGreaterUserGroup() {
    const userGroup = await this.getGroups();
    if (userGroup) {
      for (const ugsba of this.catGroupsSortedByAuthority) {
        if (userGroup.includes(ugsba)) {
          return Object.keys(UserGroup)[Object.values(UserGroup).indexOf(ugsba)];
        }
      }
    }
    return null;
  }
  async findGreaterUserGroupCfg() {
    const userGroup = await this.getGroups();
    if (userGroup) {
      for (const ugsba of this.cfgGroupsSortedByAuthority) {
        if (userGroup.includes(ugsba)) {
          return Object.keys(UserGroup)[Object.values(UserGroup).indexOf(ugsba)];
        }
      }
    }
    return null;
  }
  async findGreaterUserGroupIdx() {
    const userGroup = await this.getGroups();
    if (userGroup) {
      for (const ugsba of this.indexGroupsSortedByAuthority) {
        if (userGroup.includes(ugsba)) {
          return Object.keys(UserGroup)[Object.values(UserGroup).indexOf(ugsba)];
        }
      }
    }
    return null;
  }
  async findGreaterUserGroupMap() {
    const userGroup = await this.getGroups();
    if (userGroup) {
      for (const ugsba of this.mapGroupsSortedByAuthority) {
        if (userGroup.includes(ugsba)) {
          return Object.keys(UserGroup)[Object.values(UserGroup).indexOf(ugsba)];
        }
      }
    }
    return null;
  }
  async findGreaterUserGroupAddressBook() {
    const userGroup = await this.getGroups();
    if (userGroup) {
      for (const ugsba of this.addressBookGroupsSortedByAuthority) {
        if (userGroup.includes(ugsba)) {
          return Object.keys(UserGroup)[Object.values(UserGroup).indexOf(ugsba)];
        }
      }
    }
    return null;
  }
  async findGreaterUserGroupDashboard() {
    const userGroup = await this.getGroups();
    if (userGroup) {
      for (const ugsba of this.dashboardGroupsSortedByAuthority) {
        if (userGroup.includes(ugsba)) {
          return Object.keys(UserGroup)[Object.values(UserGroup).indexOf(ugsba)];
        }
      }
    }
    return null;
  }

}
